import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { useCallback, useState } from "react";
import { DFlex } from "../display/DFlex";
import { jobApi, toasted } from "../helpers";
import { useDialog } from "../helpersReact";

type ManualInvoiceDialogProps = {
	jobId: number;
	onSuccess: () => void;
};
const ManualInvoiceDialog = ({
	jobId,
	onSuccess,
}: ManualInvoiceDialogProps) => {
	const [value, setValue] = useState<string>();
	const handleSuccess = useCallback(async () => {
		if (value) {
			toasted(
				jobApi.bff
					.bffJobManualInvoiceCreate({
						invoiceNumber: value,
						jobId,
					})
					.then(onSuccess),
				"Invoicing job",
			);
		}
	}, [onSuccess, value, jobId]);
	return (
		<div>
			<p>
				Please input the invoice number for job <b>{jobId}</b>
			</p>
			<Input value={value} onChange={(e) => setValue(e.value)} maxLength={50} />
			<br />
			<br />
			<DFlex flexEnd>
				<Button themeColor="primary" onClick={handleSuccess} disabled={!value}>
					Submit
				</Button>
			</DFlex>
		</div>
	);
};

export const useManualInvoiceDialog = (onSuccess: () => void) => {
	const [jobId, setJobId] = useState<number>();
	const [_toggleDialog, dialog] = useDialog(
		jobId && (
			<ManualInvoiceDialog
				jobId={jobId}
				onSuccess={() => {
					_toggleDialog(false);
					onSuccess();
				}}
			/>
		),
		`Manual Invoice: ${jobId}`,
	);

	const toggleDialog = useCallback(
		(id: number) => {
			setJobId(id);
			_toggleDialog();
		},
		[_toggleDialog],
	);

	return [toggleDialog, dialog] as const;
};
