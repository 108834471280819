import { useNavigate } from "react-router-dom";
import styles from "./invoice-job-link.module.css";

interface InvoiceJobLinkProps {
	id: number;
	uniqueId: string;
}

export const InvoiceJobLink = ({ id, uniqueId }: InvoiceJobLinkProps) => {
	const navigate = useNavigate();

	const handleNavigate = () => {
		navigate(`/jobs2/${id}`, { state: { from: window.location.pathname } });
	};

	return (
		<span
			className={styles.link}
			onClick={handleNavigate}
			onKeyUp={handleNavigate}
		>
			{uniqueId}
		</span>
	);
};
