import {
	Button,
	DropDownButton,
	DropDownButtonItem,
} from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import { Card } from "@progress/kendo-react-layout";
import {
	checkIcon,
	chevronLeftIcon,
	copyIcon,
	dollarIcon,
	equalIcon,
	pauseIcon,
	playIcon,
	printIcon,
	trashIcon,
	zoomInIcon,
} from "@progress/kendo-svg-icons";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import type { JobDetailsResponse } from "../../api/JobApi";
import { DFlex } from "../../display/DFlex";
import { JobStatusTag } from "../../helpersReact";

const useActions = (actions: JobDetailsResponse["actions"]) =>
	useMemo(
		() =>
			[
				{ text: "Pause", icon: pauseIcon, disabled: !actions.allowPause },
				{ text: "Resume", icon: playIcon, disabled: !actions.allowResume },
				{ text: "Audit", icon: zoomInIcon, disabled: !actions.allowAudit },
				{
					text: "Duplicate",
					icon: copyIcon,
					disabled: !actions.allowDuplicate,
				},
				{
					text: "Force Complete",
					icon: checkIcon,
					disabled: !actions.allowForceComplete,
				},
				{
					text: "Send to Invoice",
					icon: dollarIcon,
					disabled: !actions.allowSendToInvoice,
				},
				{
					text: "Preview Invoice",
					icon: printIcon,
					disabled: !actions.allowPreviewInvoice,
				},
				{
					text: "Generate File Front",
					icon: printIcon,
					disabled: !actions.allowGenerateFileFront,
				},
				{
					text: "Delete",
					icon: trashIcon,
					disabled: !actions.allowCancel,
				},
				{
					text: "Multi Edit",
					icon: equalIcon,
					disabled: !actions.allowEdit,
				},
			] as const,
		[actions],
	);
type PossibleAction = ReturnType<typeof useActions>[number]["text"];
type JobDetailsTitleProps = {
	data: JobDetailsResponse;
	onGoBackClick: () => void;
	onActionClick: (action: PossibleAction) => void;
};
export const JobDetailsTitle = ({
	data,
	onGoBackClick,
	onActionClick,
}: JobDetailsTitleProps) => {
	const actions = useActions(data.actions);
	const navigate = useNavigate();
	return (
		<Card>
			<DFlex vCenter fullHeight spaceBetween margin="0 16px">
				<DFlex vCenter>
					<SvgIcon
						icon={chevronLeftIcon}
						onClick={onGoBackClick}
						style={{ cursor: "pointer" }}
					/>
					<div>
						<h3>{data.uniqueId}</h3>
						<small>
							Created at{" "}
							{dayjs(data.creationTime).format("HH:mm:ss, DD/MM/YYYY")} by{" "}
							{data.creationByUserName}
						</small>
					</div>
					<JobStatusTag status={data.status} />
				</DFlex>
				<DFlex vCenter>
					<Button
						themeColor="tertiary"
						fillMode="outline"
						onClick={() => navigate(`/jobs/${data.id}`)}
					>
						✨ Old view!
					</Button>
					<DropDownButton
						text="Actions"
						fillMode="outline"
						icon="arrow-chevron-down"
						onItemClick={(e) => onActionClick(e.item.text)}
					>
						{actions.map((action) => (
							<DropDownButtonItem
								key={action.text}
								text={action.text}
								svgIcon={action.icon}
								disabled={action.disabled}
							/>
						))}
					</DropDownButton>
				</DFlex>
			</DFlex>
		</Card>
	);
};
